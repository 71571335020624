<template>
  <div class="pbz-font">
    <div class="d-flex justify-content-center" style="margin: 30px 0">
      <div class="menu-page">
        <div
          :style="{
            color: accountSetting ? '#FF0000' : '',
            borderBottom: accountSetting ? '2px solid #FF0000' : '',
            marginRight: '30px',
            cursor: 'pointer',
          }"
          @click="handleMenu('accountSetting')"
        >
          {{ $t('account.setting') }}
        </div>
        <div
          :style="{
            color: changedPassword ? '#FF0000' : '',
            borderBottom: changedPassword ? '2px solid #FF0000' : '',
            cursor: 'pointer',
          }"
          @click="handleMenu('changedPassword')"
        >
          {{ $t('account.password') }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div
        class="box-content"
        :style="{ height: accountSetting ? '730px' : '' }"
      >
        <!-- change password  -->
        <a-row v-if="changedPassword" style="padding: 50px">
          <a-col :span="24" class="d-flex" style="padding-bottom: 30px">
            {{ $t('account.setPassword') }}
          </a-col>
          <a-col :span="24">
            <a-input-password
              v-model="oldPassword"
              class="field-pass"
              placeholder="Kata Sandi Saat Ini"
            />
          </a-col>
          <a-col
            v-if="error.oldPassword && !oldPassword"
            :span="12"
            style="color: red"
            class="d-flex justify-content-start"
          >
            {{ error.oldPassword }}
          </a-col>
          <a-col
            v-if="error.oldPasswordNotMatch"
            :span="12"
            style="color: red"
            class="d-flex justify-content-start"
          >
            {{ error.oldPasswordNotMatch }}
          </a-col>
          <a-col :span="24">
            <a-input-password
              v-model="newPassword"
              class="field-pass"
              placeholder="Kata Sandi Baru"
            />
          </a-col>
          <a-col
            v-if="error.newPassword && !newPassword"
            :span="12"
            style="color: red"
            class="d-flex justify-content-start"
          >
            {{ error.newPassword }}
          </a-col>
          <a-col :span="24">
            <a-input-password
              v-model="newPasswordConfirm"
              class="field-pass"
              placeholder="Konfirmasi Kata Sandi Saat Baru"
            />
          </a-col>
          <a-col
            v-if="
              (error.newPasswordConfirm && !newPasswordConfirm) ||
                newPassword !== newPasswordConfirm
            "
            :span="12"
            style="color: red"
            class="d-flex justify-content-start"
          >
            {{ error.newPasswordConfirm }}
          </a-col>
          <a-col :span="24" class="d-flex justify-content-end">
            <a-button
              :disabled="!oldPassword || !newPassword || !newPasswordConfirm"
              class="submit-button"
              @click="handleShowModal('changePass')"
            >
              Simpan Perubahan
            </a-button>
          </a-col>
        </a-row>

        <!-- Account setting  -->
        <a-form-model ref="formAccount" :model="formEditAccount" @submit.prevent="submitEditAccount">
          <a-row v-if="accountSetting" style="padding: 50px">
            <a-col :span="24" class="d-flex mb-3" style="padding-bottom: 30px">
              {{ $t('account.profile') }}
            </a-col>
            <a-col :span="24">
              <label>Email</label>
              <a-form-model-item prop="email" :rules="[{ required: true, message: 'Email is required' }]">
                <a-input 
                  v-model="formEditAccount.email"
                  :disabled="disableEdit"
                  style="height: 48px" 
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <label>Username</label>
              <a-form-model-item prop="username" :rules="[{ required: true, message: 'Username is required' }]">
                <a-input 
                  v-model="formEditAccount.username"
                  style="height: 48px"
                  disabled
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <label>{{ $t('account.fullname') }}</label>
              <a-form-model-item prop="full_name" :rules="[{ required: true, message: 'Nama Lengkap is required' }]">
                <a-input 
                  v-model="formEditAccount.full_name"
                  style="height: 48px" 
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <label>{{ $t('account.noHp') }}</label>
              <a-form-model-item prop="phone_number" :rules="[{ required: true, message: 'No. Handphone is required' }]">
                <a-input 
                  v-model="formEditAccount.phone_number"
                  style="height: 48px"
                  @keydown="phoneRule($event)"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" class="d-flex justify-content-end">
              <a-form-model-item>
                <a-button 
                  class="submit-button"
                  :disabled="isSubmitDisabled"
                  html-type="submit"
                >
                  <div v-if="!loading">
                    Simpan Perubahan
                  </div>
                  <div v-if="loading">
                    <a-icon type="loading" />
                  </div>
                </a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </div>
    <ModalUserManagement
      :is-loading="loading"
      :show-modal-change-password="showModalChangePassword"
      :show-modal-succes-change-pass="showModalSuccesChangePass"
      @handleShowModal="handleShowModal"
      @submitButton="submitButton"
    />
  </div>
</template>

<script>
import { getUserDetailById, updateUser, changePassword } from '@/api/user'
import ModalUserManagement from '@/components/UserManagement/ModalUserManagement/index.vue'

export default {
  components: {
    ModalUserManagement,
  },
  data: function () {
    return {
      accountSetting: true,
      changedPassword: false,
      oldPassword: null,
      newPassword: null,
      newPasswordConfirm: null,
      formEditAccount: {
        email: '',
        username: '',
        full_name: '',
        phone_number: '',
      },
      error: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        oldPasswordNotMatch: '',
      },
      showModalChangePassword: false,
      showModalSuccesChangePass: false,
      loading: false,
      disableEdit: false,
      username: '',
      full_name: '',
      phone_number: '',
    }
  },
  computed: {
    isSubmitDisabled() {
      return this.username !== this.formEditAccount.username || this.full_name !== this.formEditAccount.full_name || this.phone_number != this.formEditAccount.phone_number
        ? false
        : true
    },
  },
  mounted() {
    this.getUserList()
  },
  methods: {
    handleMenu(type) {
      switch (type) {
        case 'accountSetting':
          this.accountSetting = true
          this.changedPassword = false
          break
        case 'changedPassword':
          this.accountSetting = false
          this.changedPassword = true
          break
      }
    },
    handleShowModal(type) {
      switch (type) {
        case 'changePass':
          if (!this.oldPassword) {
            this.error.oldPassword = 'Kata sandi saat ini tidak boleh kosong'
          }
          if (!this.newPassword) {
            this.error.newPassword = 'Kata sandi baru tidak boleh kosong'
          }
          if (!this.newPasswordConfirm) {
            this.error.newPasswordConfirm =
              'Konfirmasi kata sandi baru tidak boleh kosong'
          }
          if (this.newPassword !== this.newPasswordConfirm) {
            this.error.newPasswordConfirm = 'Kata Sandi tidak sama'
          }

          if (
            this.oldPassword &&
            this.newPassword &&
            this.newPasswordConfirm &&
            this.newPassword === this.newPasswordConfirm
          ) {
            this.showModalChangePassword = true
          }
          break
        case 'succesChangePass':
          this.showModalSuccesChangePass = true
          break
        case 'close':
          this.showModalChangePassword = false
          this.showModalSuccesChangePass = false
          break
      }
    },
    async getUserList() {
      await getUserDetailById()
      .then(({ data: { data: response } }) => {
        this.formEditAccount = response
        this.username = response.username
        this.full_name =  response.full_name
        this.phone_number = response.phone_number
        if (response.email != '') {
          this.disableEdit = true
        }
      })
      .catch((err) => console.error(err))
    },
    submitButton() {
      if (this.accountSetting) {
        this.submitEditAccount()
      } else if (this.changedPassword) {
        this.submitChangePassword()
      }
    },
    submitEditAccount() {
      this.loading = true
      this.$refs.formAccount.validate(async valid => {
        if (!valid) return
        const payload = {
          id: this.formEditAccount.id,
          email: this.formEditAccount.email,
          username: this.formEditAccount.username,
          full_name: this.formEditAccount.full_name,
          phone_number: this.formEditAccount.phone_number,
          status: this.formEditAccount.status,
          workspace_role_id: this.formEditAccount.user_workspaces.find(item => item.workspace_role.workspace_id === this.$route.query.workspace_id).workspace_role.workspace_role_id,
        }
        await updateUser({
          type: '',
          data: payload,
        })
        .then(() => {
          this.$notification.success({
            message: 'Data Berhasil diupdate',
          })
          setTimeout(() => {
            this.loading = false
            window.location.reload()
          }, 1000)
        })
        .catch((err) => {
          this.loading = false
          this.$notification.error({
            message: err.response?.data?.message,
          })
        })
      })
    },
    async submitChangePassword() {
      this.loading = true
      const payload = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
        confirm_password: this.newPasswordConfirm,
      }
      await changePassword(payload)
      .then(() => {
        this.$notification.success({
          message: 'Password berhasil diubah',
        })
        setTimeout(() => {
          this.loading = false
          this.showModalChangePassword = false
          this.showModalSuccesChangePass = true
          this.$store
            .dispatch('user/LOGOUT')
            .then(() => {
              const url = new URL(window.location.href)
              url.pathname = '/'
              
              if (this.$keycloak.token) {
                this.$keycloak.logout({
                  redirectUri: url.href,
                })
              } else {
                window.location.href = window.location.origin
              }
            })
            .catch((err) => {
              console.error(err)
            })
        }, 1000)
      })
      .catch((err) => {
        this.loading = false
        this.error.oldPasswordNotMatch = err.response.data.message
        this.$notification.error({
          message: err.response.data.message,
        })
        this.handleShowModal('close')
      })
    },
    phoneRule(e) {
      if (/^\D$/.test(e.key)) {
        e.preventDefault()
      }
    },
  },
}
</script>

<style lang='scss'>
.menu-page {
  width: 80%;
  display: flex;
}
.box-content {
  width: 80%;
  height: 426px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;

  .label-form {
    position: absolute;
    left: 15px;
    top: -11px;
    background: #ffffff;
    color: #b3b3b3;
  }

  .field-pass {
    border: 1px solid #cccccc;
    margin: 10px 0;
    height: 48px;
    box-sizing: border-box;
    border-radius: 5px;

    .ant-input {
      height: 100%;
    }
  }
  .submit-button {
    width: 196px;
    height: 48px;
    border-radius: 5px;
    background: #e00000;
    color: #ffffff;
    font-size: 15px;
    margin-top: 20px;
  }
}
</style>
